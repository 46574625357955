/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


       


        'use strict';

        function initNavbar () {

          if (!$('section:first').is('.parallax, #home, .splash')) {
            $('#topnav').addClass('scroll');
            $('body').addClass('top-padding');
          }

          if ($('section:first').is('#home') && $('#home').hasClass('bordered')) {
            $('#topnav').addClass('top-space');
          }

          $(window).scroll(function() {
            
            if($('section:first').is('.parallax, #home, .splash')){
              if ($(window).scrollTop() >= 100 ) {
                $('#topnav').addClass('scroll');
              } else{
                $('#topnav').removeClass('scroll');
              }
            }

            

          }).trigger('scroll');

          $('.navbar-toggle').on('click', function(event) {
            $(this).toggleClass('open');
            $('#navigation').slideToggle(400);
            //$('.cart, .search').removeClass('open');
          });

       

          $('.navigation-menu>li').slice(-2).addClass('last-elements');

          $('.navigation-menu li.has-submenu a[href="#"]').on('click', function(e) {
            if ($(window).width() < 992) {
              e.preventDefault();
              $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
          });
        }

        function initHomeSlider() {

          $('#home-slider img').each(function(index, el) {
            var slide = $(this).parent('li');
            var image = $(this).attr('src');

            $(slide).prepend($('<div class="slide-image"></div>').css('background-image', 'url('+image+')'));

            if (navigator.userAgent.indexOf("Firefox") != -1 && $('#home').hasClass('bordered')) {
              $('.slide-image').addClass('ff-fix');
            }

            $(this).remove();
          });

          var options = {
            prevText: '<i class="ti-angle-left"></i>',
            nextText: '<i class="ti-angle-right"></i>',
            keyboard: false,
          };

          if ($('#home-slider .slides > li').length < 2) {
            options.directionNav = false
          }

          if ($('#home-slider').hasClass('kenburn')) {

            options.start = function () {
              $('#home-slider').find(".slides > li.flex-active-slide > .slide-image").each(function () {
                var $content = $(this);
                $content.css({
                  '-webkit-transform': 'scale(1.2)',
                  '-moz-transform': 'scale(1.2)',
                  'transform': 'scale(1.2)',
                });
              })
            }

            options.before = function () {
              $('#home-slider').find(".slides > li > .slide-image").each(function () {
                var $content = $(this);
                $content.css({
                  '-webkit-transform': 'scale(1)',
                  '-moz-transform': 'scale(1)',
                  'transform': 'scale(1)',
                });
              })
            }

            options.after = function () {
              $('#home-slider').find(".slides > li.flex-active-slide > .slide-image").each(function () {
                var $content = $(this);
                $content.css({
                  '-webkit-transform': 'scale(1.2)',
                  '-moz-transform': 'scale(1.2)',
                  'transform': 'scale(1.2)',
                });
              })
            }
          }

          $('#home-slider').flexslider(options);


        }

        function initCarousels () {
          $('.owl-carousel').each(function(index, el) {
            var dataOptions = $(this).data('options') || {};

            var options = {
              items: dataOptions.items || 6,
              loop: dataOptions.loop || true,
              slideBy: dataOptions.slideBy || 6,
              dots: dataOptions.dots || false,
              margin: dataOptions.margin || 10,
              autoplay: dataOptions.autoplay || 8000,
              slideSpeed: dataOptions.slideSpeed || 1,
              responsiveClass: true,
              nav: true,
              navText: ["<img src='/wp-content/themes/itex/dist/images/back.svg'>","<img src='/wp-content/themes/itex/dist/images/next.svg'>"],
              responsive:{
                0:{
                  items: dataOptions.xsItems || 2,
                  margin: 25
                },
                768:{
                  items: dataOptions.smItems || 2,
                },
                992:{
                  items:dataOptions.mdItems || 4,
                },
                1200: {
                  items: dataOptions.items || 6
                }
              }
            }

            if (options.autoplay) {
              options.autoplayTimeout = dataOptions.autoplayTimeout || 8000;
              options.autoplayHoverPause = true;
            }


            $(el).owlCarousel(options);
          });
        }

        function initSliders () {
          $('.flexslider').each(function(index, el) {
            var dataOptions = $(this).data('options') || {};

            var options = {
              animation: dataOptions.animation === 'slide' ? 'slide' : 'fade',
              controlNav: dataOptions.controlNav === true ? true : false,
              directionNav: dataOptions.directionNav === true ? true : false,
              prevText: '<i class="ti-arrow-left"></i>',
              nextText: '<i class="ti-arrow-right"></i>',
            };

            $(el).flexslider(options);
          });
        }




        function initAccordions () {
          $('.accordion-title').on('click', function(event) {
            var accordion = $(this).parents('.accordion');

            if (!accordion.data('multiple')) {
              accordion.find('li').not($(this).parent()).removeClass('active');
              accordion.find('li').not($(this).parent()).find('.accordion-content').slideUp(300);
            }

            $(this).parent('li').toggleClass('active');
            $(this).next().slideToggle(300, function () {
              fixScroll();
            });

          });
        }



        function initLoad () {
          $(window).load(function() {

            $("#loader").delay(500).fadeOut();
            $("#mask").delay(800).fadeOut("slow");

          });
        }

    

        function initPhotoGallery () {

          var imagesArray = [];

          $('.photo-gallery').on('click', '.gallery-item a', function(event) {
            event.preventDefault();

            var gallery = $(this).parents('.photo-gallery');
            var galleryElements = gallery.find('.gallery-item a');

            for (var i = 0; i < galleryElements.length; i++) {
              imagesArray.push($(galleryElements[i]).attr('href'));
            };

            var image = $(this).attr('href');

            var template = '<div id="gallery-modal">';
            template += '<div class="centrize">';
            template += '<div class="v-center">';
            template += '<div class="gallery-image">';
            template += '<a href="#" id="gallery-close"><i class="ti-close"></i></a>';
            template += '<a href="#" class="gallery-control gallery-prev"><i class="ti-angle-left"></i></a>';
            template += '<img src="'+imagesArray[imagesArray.indexOf(image)]+'" alt="">';
            template += '<a href="#" class="gallery-control gallery-next"><i class="ti-angle-right"></i></a>';
            template += '</div>';
            template += '</div>';
            template += '</div>';
            template += '</div>';

            $('body').append(template);
            $('body').addClass('modal-open');

            $('#gallery-modal').fadeIn(300);

          });

          $('body').on('click', '.gallery-control', function(event) {
            event.preventDefault();
            event.stopPropagation();

            var currentImage = $('.gallery-image').find('img');

            if ($(this).hasClass('gallery-next')) {
              if (imagesArray.indexOf(currentImage.attr('src')) >= (imagesArray.length - 1)) {
                return false;
              }

              currentImage.fadeOut(300, function() {
                var nextImage = imagesArray[imagesArray.indexOf(currentImage.attr('src')) + 1]
                $(currentImage).attr('src', nextImage);
              }).fadeIn(300);
            }

            else if ($(this).hasClass('gallery-prev')) {
              if (imagesArray.indexOf(currentImage.attr('src')) < 1) {
                return false;
              }

              currentImage.fadeOut(300, function() {
                var nextImage = imagesArray[imagesArray.indexOf(currentImage.attr('src')) - 1]
                $(currentImage).attr('src', nextImage);
              }).fadeIn(300);

            }

          });

          $('body').on('click', '#gallery-close', function(event) {
            event.preventDefault();
            $('#gallery-modal').fadeOut(300, function() {
              $('#gallery-modal').remove();
            });
            $('body').removeClass('modal-open');
          });

          $('body').on('click', '.gallery-image', function(event) {
            event.stopPropagation();
          });

          $('body').on('click', '#gallery-modal', function(event) {
            $('#gallery-close').trigger('click');
          });

          $(document).keyup(function(e) {
            if (e.keyCode == 27) {
              $('#gallery-close').trigger('click');
            }
            if (e.keyCode == 37) {
              $('.gallery-control.gallery-prev').trigger('click');
            }
            if (e.keyCode == 39) {
              $('.gallery-control.gallery-next').trigger('click');
            }
          });
        }

        

      

        function fixScroll() {
          $('#sscr').css('height', 0);
          $('#sscr').css('height', document.documentElement.scrollHeight + 'px');
        }


        function initGeneral () {

          $("a[href='#top']").on('click', function() {
            $("html, body").animate({ scrollTop: 0 }, 1000);
            return false;
          });

          $('a[data-scroll="true"]').on('click', function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });

          if ($('#navigation').data('onepage')) {
            $('body').scrollspy({
              target: '#navigation'
            });
          }

          $('.bg-img, .thumb-placeholder').each(function(index, el) {
            var image = $(el).attr('src');
            $(el).parent().css('background-image', 'url(' + image + ')');
            $(el).remove();
          });

          $('.alert').on('closed.bs.alert', function () {
            fixScroll();
          });

          $('body').on('click', '.alert', function() {
            $(this).on('closed.bs.alert', function() {
              fixScroll();
            });
          });

          

          $('#login-tabs a:first').tab('show');

          $('#login-content .tab-pane:first').addClass('fade in');

          $('#login-tabs li a').on('click', function (e) {
            e.preventDefault();
            $(this).tab('show');
          });

          $('a[data-toggle=tab]').on('click', function(event) {
            $(window).trigger('resize');      
          }).on('shown.bs.tab', function(e) {
            var container = $($(this).attr('href'));

            if (container.find('.progress-bar').length) {
              container.find('.progress-bar').each(function(index, el) {
                $(el).css('width', $(this).data('progress') + '%');
                $(el).parents('.skill').find('.skill-perc').css('right', 100 - $(el).data('progress') + '%');
              });
            }

          });;


          // init stellar.js
          $(window).stellar();



          $('.boxes [data-bg-color]').each(function(index, el) {
            $(el).css('background-color', $(el).data('bg-color'));  
          });

          

          $('[data-animated=true]').addClass('invisible');

          

          $('.client-image').hover(function() {
            $(this).removeClass('fade-in-top')
          }, function() {
            //
          });
        }

        function initCustom () {
          // Your custom code here.
        }

        function init () {
          initNavbar();
          initHomeSlider();
          initCarousels();
          initSliders();
          initAccordions();
          initLoad();
        //  initVideoBg();
        //  initVideoModal();
          initPhotoGallery();
          //initContactForm();
        //  initCounters();
          //initForms();
          initGeneral();
          initCustom();


        }

        init();

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $("#loader").delay(3000).fadeOut();

        $('.icon-box-small').matchHeight();
        $('#home-services .ib-content').matchHeight();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },


    // About us page, note the change from about-us to about_us.
    'products': {
      init: function() {
        // JavaScript to be fired on the about us page
        
         $('.shop-product').matchHeight();

      }
    },




    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page

        //Google Maps JS
        function initialize() {
          var myLatlng = new google.maps.LatLng(53.463894, -2.325164);
          var imagePath = '/wp-content/themes/itex/dist/images/marker.svg'
          
          var styles = [{"featureType":"all","elementType":"all","stylers":[{"hue":"#00ffbc"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-70}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"simplified"},{"saturation":-60}]}];

          var mapOptions = {
            zoom: 15,
            center: myLatlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            styles: styles
          }

        var map = new google.maps.Map(document.getElementById('map'), mapOptions);
        //Callout Content
        var contentString = 'Behrens Group,<br>Centrepoint,<br>Marshall Stevens Way,<br>Trafford Park,<br>Manchester,<br>M17 1PP';
        //Set window width + content
        var infowindow = new google.maps.InfoWindow({
          content: contentString,
          maxWidth: 500
        });

        //Add Marker
        var marker = new google.maps.Marker({
          position: myLatlng,
          map: map,
          icon: imagePath,
          title: 'Find us here'
        });

        google.maps.event.addListener(marker, 'click', function() {
          infowindow.open(map,marker);
        });

        //Resize Function
        google.maps.event.addDomListener(window, "resize", function() {
          var center = map.getCenter();
          google.maps.event.trigger(map, "resize");
          map.setCenter(center);
        });
      }

      google.maps.event.addDomListener(window, 'load', initialize);



      }
    },



  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
